@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Gantari:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
* {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif; */
}
body {
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 500;
}
a {
  color: inherit;
}

.ant-layout-header,
#pageTitle {
  font-family: "Nunito Sans";
  font-size: 16px;
  letter-spacing: 2px;
}
.ant-layout-sider {
  font-family: "Nunito Sans";
  background-color: #444;
  width: 300px;
  height: 100vh;
}
.ant-layout-header {
  background-color: #222;
  color: #fff;
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;
}
.ant-layout-header .logo {
  font-size: 18px;
  letter-spacing: 1px;
}
.ant-layout-header .title {
  font-size: 16px;
  letter-spacing: 2px;
}
.ant-layout-header .trigger {
  font-size: 24px;
  line-height: inherit;
  display: flex;
}

.ant-menu {
  font-size: 14px;
  font-weight: 500;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: #333;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #222;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: #fff;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: #fff;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  border-bottom: 2px solid #444;
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  margin: 0px;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 60px;
}
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-item {
  background-color: #222;
}
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  background-color: #222;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 60px;
  /* background-color: #222; */
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0px;
}
.ant-menu-dark .ant-menu-item:hover {
  background-color: #222;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
  border-right: 4px solid #2b99ff;
}

.topMenu > li {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 64px;
  border-left: 2px solid #444 !important;
  border-bottom: 2px solid #444 !important;
}
.topMenu li svg {
  font-size: 20px;
  line-height: 20px;
  height: 20px;
  width: 20px;
}
.topMenu li svg + span {
  display: none;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: #222;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #222;
}
.mt-2 {
  margin-top: 4px;
}
.m-2 {
  margin: 4px;
}
.ant-btn {
  font-weight: 600;
  border-radius: 6px;
  /* font-size: 14px; */
  font-family: "Nunito Sans";
  background-color: #cdcdcd;
  color: #000;
}
.ant-btn:hover,
.ant-btn:focus {
  color: #000;
  border-color: #cdcdcd;
  background-color: #cdcdcd;
}
.ant-btn-dangerous {
  background-color: transparent;
  color: #ff4d4f;
  border-color: #ff4d4f;
  border-radius: 6px !important;
}
.ant-btn-danger {
  background-color: #ff4d4f;
  color: #fff;
  border-color: #ff4d4f;
  border-radius: 6px !important;
}
.ant-btn-danger:hover,
.ant-btn-danger:focus {
  background-color: #ff4d4f;
  color: #fff;
  border-color: #ff4d4f;
}
.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
  background-color: transparent;
  color: #ff4d4f;
  border-color: #ff4d4f;
}

.ant-input,
.ant-card-head-title
/* ,
h1, h2, h3, h4, h5, h6 */ {
  font-family: "Nunito Sans";
}
.ant-btn-primary {
  background-color: #007aea;
  border-color: #007aea;
  /* background-color: #DC0077;
  border-color: #DC0077; */
  color: #fff;
  /* border-color: #1890ff;
  background: #1890ff; */
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  border-color: #007aea;
  background-color: #007aea;
}

.ant-btn-success {
  /* background-color: mediumseagreen;
  border-color: mediumseagreen; */
  color: #fff;
}
.ant-btn-danger {
  /* background-color: #d33078;
  border-color: #d33078; */
}
.ant-layout-content {
  background-color: #fff;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 15px;
  padding-right: 15px;
  height: calc(100vh - 64px);
  overflow: auto;
}
.bg-light {
  background-color: aliceblue;
  background-color: #d6ecff;
  background-color: #f0f6fd;
}
.ant-space-vertical {
  width: 100%;
}
.ant-card-body {
  font-size: 15px;
}
.ant-divider-dashed {
  border-color: #ccc;
}

.ant-typography-edit-content textarea {
  border-color: #000;
  color: #000;
  font-weight: 500;
}
div.ant-typography-edit-content {
  margin-top: 0px;
  margin-bottom: 0px;
}

.ant-input:focus,
.ant-input:hover {
  border-color: #000;
}
.ant-input::placeholder {
  color: #999;
  font-weight: 500;
}
.ant-space-item {
  /* width: max-content; */
}
.ant-table-cell {
  /* text-transform: capitalize; */
}
.ant-image-preview-mask {
  background-color: #000;
}

/* .ant-progress-inner {
  background-color: rgb(255 255 255);
  border: none;
  border: 1px solid #daebff;
  border-radius: 0;
}
.ant-progress-success-bg, .ant-progress-bg {
  border-radius: 0;
} */

.ant-layout-sider-zero-width-trigger {
  display: none;
}

.membership p {
  font-size: 15px;
}

.membership a {
  color: #5a87a4;
}

.tab_design {
  display: flex;
  display: -ms-flexbox;
  justify-content: space-between;
}

.fs-13 {
  font-size: 13px !important;
}

/* .cstBtnWidth {
  width: 100%;
} */

.cstBtnWidth button {
  width: 145px;
  max-width: 100%;
}

.cstBtn button {
  width: 225px;
  max-width: 100%;
}

@media (min-width: 768px) {
  .ant-layout-content > .ant-divider-dashed {
    margin-left: -24px;
    margin-right: -24px;
    width: auto;
  }
}

@media (max-width: 991.98px) {
  .tab_design {
    gap: 0.5em;
  }

  .tab_design button.w-full {
    width: 70%;
    align-self: flex-start;
  }
}

@media (max-width: 767.98px) {
  .ant-layout-content .ant-col-17 {
    flex: 0 0 65%;
    max-width: 65%;
  }

  .tab_design button.w-full {
    width: 80%;
  }

  .ant-layout-sider {
    /* max-height: 100vh; */
    overflow: auto;
    position: absolute;
    top: 60px;
    right: 0;
  }
}

@media (max-width: 640px) {
  .tab_design div.w-full {
    width: auto;
  }
}

@media (max-width: 575.98px) {
  .cstBtn,
  .cstRedBtn {
    width: 180px !important;
    max-width: 100%;
  }

  .cstBtnWidth button {
    width: 180px;
  }

  .riskbtn {
    width: 180px !important;
  }

  .mob\:items-center {
    align-items: center !important;
  }

  .sm\:w-full {
    width: 100% !important;
  }

  .tab_design {
    flex-direction: column;
  }

  .tab_design button.w-full,
  .tab_design div.w-full {
    /* width: 90%; */
  }

  .toggler {
    width: 50px !important;
  }

  .ant-layout-content .ant-col-20 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ant-layout-header {
    padding: 0 15px;
  }

  .ant-layout-header .logo {
    font-size: 14px;
  }

  #pageTitle {
    font-size: 14px;
  }

  .ant-layout-content .ant-col-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ant-layout-content .ant-col-17 {
    flex: 0 0 55%;
    max-width: 55%;
  }

  .ant-layout-content .ant-col-3 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .ant-layout-content .tblRow .ant-col-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 475.98px) {
  .ant-layout-content .ant-col-17,
  .ant-layout-content .ant-col-3,
  .ant-layout-content .ant-col-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .addressWrap .ant-col-12,
  .addressWrap .ant-col-24 .ant-col-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .addressWrap .ant-row,
  .passWrap .ant-row,
  .pinwrap .ant-row {
    row-gap: 12px !important;
  }

  .passWrap .ant-col,
  .pinwrap .ant-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.text-right {
  text-align: right !important;
  margin: 15px;
}

.input:focus {
  outline: none !important;
  color: #6a4e4d !important;
  background-color: white !important;
}

.ant-select-selection-placeholder {
  color: black;
  display: flex;
  justify-content: start;
}
